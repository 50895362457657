<template>
  <v-card fluid
          class="pharmen mt-1">
    <v-list two-line
            dense
            max-height="420">
      <v-container>
        <v-list-item-title class="pl-2"
                           style="align-self: start">DIN VARUKORG</v-list-item-title>
      </v-container>
      <template v-if="$store.getters.carted_count > 0">
        <div v-for="item in $store.getters.carted_items"
             :key="item.title">
          <v-list-item class="pb-0">
            <v-list-item-avatar rounded="0">
              <v-img contain
                     :src="item.image_link"> </v-img>
            </v-list-item-avatar>
            <div>
              <v-list-item-content style="max-width: 180px; min-width: 180px;">
                <v-list-item-action-text v-text="item.title"></v-list-item-action-text>
                <v-list-item-subtitle class="newPrice red--text">
                  {{ item.total_price() }};-
                </v-list-item-subtitle>

              </v-list-item-content>
            </div>
            <div class="d-flex">
              <v-text-field prepend-icon="mdi-minus"
                            append-outer-icon="mdi-plus"
                            v-model="item.carted"
                            :value="item.carted"
                            class="centered-input some-style"
                            hide-details
                            @click:prepend="$store.commit('change_cart', { product: item, n: -1 })"
                            @click:append-outer="$store.commit('change_cart', { product: item, n: 1 })"></v-text-field>
            </div>
            <v-btn icon
                   style="position:absolute; right: 5px; top: -5px; transform: scale(0.7);"
                   @click.stop="$store.commit('change_cart',
                   { product: item, n: -item.carted })">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <!-- <v-divider vertical></v-divider> -->



          </v-list-item>
          <v-list-item style="max-height: 4px; min-height: 4px;">
            <v-divider></v-divider>
          </v-list-item>
        </div>
        <v-container>
          <v-list-item-title class="pl-2"
                             style="align-self: start">VI REKOMMENDERAR</v-list-item-title>
          <RecommendationList :items="recommendations" />
        </v-container>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-title class="text-center">Din varukorg är tom!</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <v-footer padless
              width="357">
      <v-container class="pa-0">

        <v-card-actions class="pa-0">
          <v-col cols="6"
                 class="pb-0 pt-0">

            <v-card-text style="font-size: 110%;">TOTALT: {{ String($store.getters.cart_sum) }};-</v-card-text>
          </v-col>

          <v-col class="d-flex justify-end pb-0 pl-0 pt-0">

            <v-card-actions>
              <router-link :to="{ name: 'checkout' }" style="text-decoration: none;">
                <v-btn color="black"
                       class="white--text"
                       @click.stop="">Till kassan</v-btn>
              </router-link>
            </v-card-actions>
          </v-col>
        </v-card-actions>
        <v-card-actions class="pt-0 d-flex align-center justify-center">
          <v-img max-width="280"
                 src="@/assets/PAYMENT_COLOR-removebg-preview.png"></v-img>
        </v-card-actions>


      </v-container>
    </v-footer>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import RecommendationList from './CartRecommendations.vue';
export default {
  data: () => ({
    recommendations: [],
  }),
  components: {
    RecommendationList,
  },
  computed: {
    ...mapGetters({
      opts: 'fetcher_opts',
    })
  },
  watch: {
    opts: {
      deep: true,
      handler: async function (options) {
        var temp_options = structuredClone(options);
        temp_options.body = JSON.stringify(temp_options.body);
        this.recommendations = [];
        const url = 'https://d16.infobaleen.com/api/v1/api-profiles/499/recommendations/items-to-items?use-cors';
        fetch(url, temp_options)
          .then(response => response.json())
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.recommendations.push(res)))
            }
          })
      },
      immediate: true,
    }
  },

}
</script>

<style scoped>
.centered-input>>>input {
  text-align: center;
}

.some-style>>>.v-input__slot::before {
  border-style: none !important;
}

.v-text-field {

  display: flex;
  text-decoration: none !important;
  transform: scale(0.8);
  max-width: 90px;
}

.v-card {
  overflow-y: hidden;
}

.v-card-title {
  overflow-y: scroll;
}

/* Sätt v-card till scroll och ta bort från v-list om man vill att 
   tool-baren ska scrolla ner */

.v-list {
  overflow-y: scroll;
}
</style>